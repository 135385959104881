.dropdown {
    position: relative;
    @media only screen and (max-width: 1100px) {
        width: 100%;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    & .dropdown-content {
        display: none;
        position: absolute;
        background-color: $header-bgcolor;
        color: #FFF;
        min-width: 130px;
        right: -30px;
        top: 34px;
        padding: .8em;
        z-index: 1;
        font-size: 0.9em;
        & a {
            color: #FFF;
            padding: .8em;
            cursor: pointer;
            line-height: 40px;
            & img {
                margin-right: 10px;
            }
            &:hover {
                color: greenyellow;
            }
        }
        @media only screen and (max-width: 1100px) {
            position: static;
            width: 100%;
            right: 0px;
            padding: 0em;
            text-align: center;
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
    }
}

.dropdown:hover .dropdown-content {
    display: block;
}