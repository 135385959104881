

.tabs{
    width: 100%;
}

.tabs .tab-header{
width: 100%;
    display: flex;
align-items: center;
justify-content: space-between;
}


.tabs .tab-header > div{
width: calc(100% / 4);
text-align: center;
color: #888;
cursor: pointer;
font-weight: 600;
font-size: 14px;
text-transform: uppercase;
}
.tabs .tab-header > div.active{
color: #8D8F47;
}

.tabs .tab-indicator{
    position: relative;
width: calc(100% / 4);
height: 5px;
background-color: #8D8F47;
left: 0px;
border-radius: 5px;
margin-top: 5px;
transition: all 500ms ease-in-out;
}

.tabs .tab-body{
    position: relative;
    /* height: calc(100% - 60px); */
    padding: 10px 0px;
}


.tabs .tab-body > .tab{
    display: none;
/* top: -200%; */
opacity: 0;
margin-top: 5px;
transform: scale(0.9);
transition: opacity 200ms ease-in-out 0ms, transform 500ms ease-in-out 0ms
}

.tabs .tab-body > .tab.active{
    /* top: 0px; */
    display: block;
    opacity: 1;
transform: scale(1);
/* margin-top: 0px; */
}