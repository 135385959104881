/* BUTTON bottom*/


$radius: .2em;

.preno_btn_onbottom{
    position: absolute;
    bottom: 0.8em; 
    width: 100%;
    @media only screen and (max-width: 768px) {
    }
}
.prenot__button_done{
    padding: .8em;
    margin: .4em;
    margin-top: 1.8em;
    text-align: center;
    background-color: $preno-color-lightgreen;
    border-radius: 5px;
    font-weight: 700;
    color: $preno-fontcolor;
    cursor: pointer;
    gap: 0.4em;
}


.preno__buttons{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 0.4em;
}

.preno__buttons__generic{
    padding: .8em;
    padding-top: .4em;
    padding-bottom: .4em;
    text-align: center;
    background-color: $preno-color-lightgreen;
    border-radius: $radius;
    font-weight: 700;
    color: $preno-font-greenbg;
cursor: pointer;
}


.preno__buttons__back{
    width: 100%;
    padding: .8em;
    text-align: center;
    background-color: $preno-bgcolor-disabled;
    color: rgb(216, 216, 216);
    border-radius: 5px;
    font-weight: 700;
cursor:pointer;
text-shadow: 0px 1px rgb(114, 114, 114)
    
}
.preno__buttons__back::before{
    content: "«";
    margin-right: 0.8em;
    font-size: 0.9rem;
}

.preno__buttons__next{
    width: 100%;
    color: #FFF;
    padding: .8em;
    text-align: center;
    background-color: $preno-color-lightgreen;
    border-radius: $radius;
    font-weight: 700;
cursor: pointer;
text-shadow: 1px 1px #000
}
.preno__buttons__next::after{
    content: "»";
    margin-left: 0.8em;
    font-size: 0.9rem;
}

