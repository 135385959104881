

  $form-check-bgcolor-checked: #968e7e;
  $form-check-bgcolor-unchecked: #fafafa;
$form-check-bgcolor-hover: #000;

  $form-check-check: #FFF;

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: $form-check-bgcolor-checked;
      border: 1px solid $form-check-check;
          color:$form-check-bgcolor-hover;
    -webkit-text-fill-color: $form-check-bgcolor-hover;
    box-shadow: 0 0 0px 1000px $form-check-bgcolor-checked inset;
    -webkit-box-shadow: 0 0 0px 1000px $form-check-bgcolor-checked inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  textarea:focus, input:focus{
      outline: none;
  }

.custom-radio{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
    align-items:stretch;
    justify-content:right;
  & div{
    margin-left: 0.8rem;
    white-space: nowrap;
  }
  
}
 
.custom-control-label:before {
  border-color: rgba($form-check-bgcolor-checked, 0.8);
  background-color: rgba($form-check-bgcolor-hover, 0.63);
  cursor: pointer;
}


.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border-color: rgba($form-check-bgcolor-checked, 0.8);
  box-shadow: 0 1px 1px rgba($form-check-bgcolor-hover, 0.63) inset, 0 0 8px rgba($form-check-bgcolor-checked, 0.8);
  outline: 0 none;
  cursor: pointer;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: rgba($form-check-bgcolor-unchecked, 0.8);
  background-color: rgba($form-check-bgcolor-hover, 0.63);
}

/* Customize the label (the container) */
.checkboxcont {
    display: block;
    position: relative;
    cursor: pointer;
    padding-right: 35px;
    min-height: 20px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 0.9em;
  }
  
  /* Hide the browser's default checkbox */
  .checkboxcont input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: $form-check-bgcolor-unchecked;
  }
  
  /* On mouse-over, add a grey background color */
  .checkboxcont:hover input ~ .checkmark {
    background-color: $form-check-bgcolor-unchecked;
    transition:linear  0.5s;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkboxcont input:checked ~ .checkmark {
    background-color: $form-check-bgcolor-checked;
    transition:linear  0.5s;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkboxcont input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkboxcont .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid $form-check-check;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


  /*SELECT */


$radius: .2em;
$baseFg: #50522a;
$baseBg: #8D8F47;
$accentFg: #50522a;
$accentBg: #8D8F47;
$color:#FFF;

select {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: $color;
  border: 1px solid $baseFg;
  line-height: 1;
  outline: 0;
  padding: 0.65em 2.5em 0.55em 0.75em;
  border-radius: $radius;
  background-color: $baseBg;
  background-image: linear-gradient($baseFg, $baseFg),
    linear-gradient(-135deg, transparent 50%, $accentBg 50%),
    linear-gradient(-225deg, transparent 50%, $accentBg 50%),
    linear-gradient($accentBg 42%, $accentFg 42%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom, right bottom;   
}

select:hover {
  background-image: linear-gradient($accentFg, $accentFg),
    linear-gradient(-135deg, transparent 50%, $accentFg 50%),
    linear-gradient(-225deg, transparent 50%, $accentFg 50%),
    linear-gradient($accentFg 42%, $accentBg 42%);
}

select:active {
  background-image: linear-gradient($accentFg, $accentFg),
    linear-gradient(-135deg, transparent 50%, $accentFg 50%),
    linear-gradient(-225deg, transparent 50%, $accentFg 50%),
    linear-gradient($accentFg 42%, $accentBg 42%);
  color: $accentBg;
  border-color: $accentFg;
  background-color: $accentFg;
}