$calendar-color:#000;
$calendar-color-noactive:#8D8F47;
$calendar-today-border:#395711;
$calendar-today-bground:#bdbdbd;
$calendar-dispo-bground:#d6cbb4;

$calendar-nodispo-bground:#395711;
$calendar-nodispo-color:#FFF;

.calendar{
    width: 100%;
    min-height: 200px;
    color: $calendar-color;
    padding: .4em;
}

.calendar-table{
    width: 100%;
    transition: 0.5s;
}

.calendar-table thead tr th:nth-child(1){
    text-align: center;
    font-weight: 400;
}

.calendar-table thead tr th:nth-child(2){
    text-align: center;
    font-weight: 700;
}
.calendar-table thead tr th:nth-child(3){
    text-align: center;
    font-weight: 400;
}
.monthcontrol{
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    border:1px solid #FFF;
    padding: 4px;
    width: 30px;
    padding-top: .5em;
    padding-bottom: .5em;
}
.daysname{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding-top: 1.8em;
    padding-bottom: .8em;
    width: 14.28%;
}
.daynumber{
    
    font-size: 16px;
    text-align: center;
    padding-top: .8em;
    padding-bottom: .8em;
    border: 1px solid transparent;
    transition: 0.3s;
    
}
.daynumber.active:hover{
    border: 1px solid $calendar-today-border;
    background-color: $calendar-today-bground;
    cursor: pointer;
}

.daynumber.noactive{
    color: #d6cbb4e3;
    cursor:not-allowed;
}

.daynumber.nodispo{
    color: $calendar-nodispo-color;
    background-color: $calendar-nodispo-bground;
    cursor:not-allowed;
}
.daynumber.nosamemonth{
    color: $calendar-color-noactive;
    cursor: pointer;
}
.daynumber.selected{
    border: 1px solid $calendar-today-border;
    background-color: $calendar-today-bground;
    cursor: pointer;
}


.legendRow{

    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #000;
    padding: 0.6em;
    gap: 1em;
    font-size: .8em;
}

.completoOchiuso{
    background-color: $calendar-nodispo-bground;
width: 20px; height: 20px;
}
.giornoSelezionatoOoggi{

    border: 1px solid $calendar-today-border;
    background-color: $calendar-today-bground;
width: 20px; height: 20px;
}
.giornoDisponibile{

    border: 1px solid #000;
    background-color: $calendar-dispo-bground;
width: 20px; height: 20px;

}