$preno-fontcolor:#000;
$preno-fontcolor-disabled: #474B4F;

$preno-bgcolor:#d6cbb4e3;
$preno-bgcolor768:#d6cbb4e3;
/*$preno-color-lightgreen: #86C232;*/
$preno-color-lightgreen:#8D8F47;
$preno-bgcolor-disabled: #8d8f47b9;
$preno-shadow-color: black;
$preno-font-greenbg:#222629;
$min-height-preno:495px;
$width-preno:420px;




.preno{
    width: 100%;
    padding: 20px 0 20px;
    /*?as=webp&width=1920*/
    background-image: url(./../assets/img/osteria_prenota.ff1412cb.jpeg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 600px;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
        padding:0px;
        width: 100%;
        justify-content:stretch;
        min-height: $min-height-preno; 
    }
}
.preno h1{
    font-size: 22px;
    color: $preno-fontcolor;
    padding: .4em;
    margin: 0;
    text-align: center;
    @media only screen and (max-width: 320px) {
        
    font-size: 18px;
    }
}

.preno__topinfo{
    font-size: 10px;
    text-align: center;
    color: $preno-fontcolor;
}

.preno__container{
    padding: .8em;
    width: $width-preno;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $preno-bgcolor;
    border: 1px solid #8D8F47;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 85px;
    animation: fadein 2s;
    -moz-animation: fadein 2s; /* Firefox */
    -webkit-animation: fadein 2s; /* Safari and Chrome */
    -o-animation: fadein 2s; /* Opera */
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-top: 0px;
        border-radius: 0px;
        padding-top: 85px;
        background-color: $preno-bgcolor768;
        border: 0px solid #000;
    }
}

/* SUMMARY */
.preno__summaryreq{

    display: none;
    padding-top: 1.8em;
    color: $preno-fontcolor;
    min-height: $min-height-preno;
    line-height: 1.6em;
    letter-spacing: 0.02em;
    position: relative;
    & h2{
        color: $preno-color-lightgreen;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0.8em;
    } 
    & .summary{
        text-align: center; 
        margin-bottom: 1.8em;
    }

    & .summaryshow{
        display: none;
    }
}

/* DATA CUSTOMER */
.preno__datareq {
    width: 100%;
    padding-top: 1.8em;
    color: $preno-fontcolor;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 0.8em;
    cursor: pointer;
    display: none;
    min-height: $min-height-preno;
    position: relative;
    & h2{
        color: $preno-color-lightgreen;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0.8em;
    } 
}
/* preno__datareq VIEW */

.preno__datareq_data{
    & div  {
    padding-bottom: 0.8em;
    color: $preno-fontcolor;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 0.2em;
    cursor: pointer;
    }
    & .preno__input{
        border: 1px solid $preno-color-lightgreen;
        padding: .6em;
        border-radius: 5px;
        font-size: 0.9rem;
        color: $preno-fontcolor;
        background-color: $preno-bgcolor;
        width: 100%;
    }
    & .othercheck{
        margin-top: 1.0em;
    }
    & .checkboxcont{
        font-size: 0.8em;
    }
}

/* INTOLERANCE VIEW */
.preno__intolerances {
    width: 100%;
    padding-top: 1.8em;
    color: $preno-fontcolor;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 0.8em;
    cursor: pointer;
    display: none;
    min-height: $min-height-preno;
    position: relative;
    & h2{
        color: $preno-color-lightgreen;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0.8em;
    } 
    & .rowIntolerance{
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-bottom: 0.8em;
        & .txtperson{
            width:100px;
            max-width:100px
        }
        & .selectperson{
            flex-grow: 1; padding-left: 0.4em; padding-right: 0.4em;
        }
    }
    & .listIntolerance{
        margin-bottom: .8em;
        margin-left: 93px;
        font-size: 0.8em;
        line-height: 20px;
        @media only screen and (max-width: 768px) {
            margin-left:113px;
            padding-left: 0.4em; padding-right: 0.4em;
        }
        @media only screen and (max-width: 380px) {
            margin-left:103px;
            padding-left: 0.4em; padding-right: 0.4em;
        }
        @media only screen and (max-width: 320px) {
            margin-left:88px;
            padding-left: 0.4em; padding-right: 0.4em;
        }
    }
}
.addinto{
    font-size: 1em;
    padding: 0px;
}
.adultschildrenlist{
    overflow-x: hidden;
    overflow-y: auto;
    
    height: 100%;
    max-height: 370px;
    padding-right: 5px;
}
/* MAIN VIEW */

.preno__main {
    order: 1;
    min-height: $min-height-preno;
    width: $width-preno;
    position: relative;
    width: 100%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    & .rowdiv{
        padding: 0.8em;
        border-bottom: 1px solid $preno-color-lightgreen;
        color: $preno-fontcolor;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        gap: 0.8em;
        cursor: pointer;
        & span:nth-child(1){  
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }
        & label{  
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        & span:nth-child(1) img{ 
            margin-right: 0.8em;
        }
        & span:nth-child(2){
            font-weight: 700;
        }

    }   

    & .checkboxcontainer{
        &.first{
            border-bottom: 0px solid;
            margin-top: 1.0em;
        }
        &.second{
            border-bottom: 0px solid;
        }
        &.houseguestSelHouse{
                    width: 100%;
            padding-left: .8rem;
            padding-right: .8rem;
            border-bottom: 0px solid;
            display: none;

            & .apartamentsContainer{
                & .addapart{
                    font-size: 1.8rem;
                    line-height: 1rem;
                    text-align: center;
                    padding: 0.5rem;
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;
                    color: #FFF;
                }
                & .selapart{
                    width: 100%;
                }
            }
            & #resAps{
                width: 100%;
                padding-top: .5rem;
                display: flex;
                flex-direction: row;
                align-items: center;
    flex-wrap: wrap;
                & .apsName{
                    margin-right: 0.5rem;
                    border: 1px solid #CCC;
padding: 0.3rem;
border-radius: .3rem;
font-size: 0.8rem;
                        color: #FFF;
cursor: pointer;
background-color: $preno-color-lightgreen;
                    &::before{
                        content: "X";
                        color: rgb(187, 186, 186);
                        margin-right: 0.5rem;
                        font-size: 0.8rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}




/* CHOISE VIEW */
.preno__choise{
    order:1;
    min-height: $min-height-preno;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    display: none;
    position: relative;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    & .prenot__button_done{
        margin: 0px;
        width: 100%;
    }
}


.choise_select{
    width: 100%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.choise__whitnumber{
    width: 100%;
    color: $preno-fontcolor;
    padding: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

}
.choise__whitnumber div img{
    margin-right: .8em;
}
.choise__whitnumber div:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100px;
    text-align: left;
}

.plus, .minus{
    width: 30px;
    height: 30px;
font-weight: 600;
font-size: 20px;

cursor: pointer;
  display: flex; /* or inline-flex */
  align-items: center; 
  justify-content: center;

background: radial-gradient( 5px -9px, circle, $preno-color-lightgreen 8%, $preno-bgcolor 26px );
background-color: $preno-bgcolor;
border: 2px solid $preno-color-lightgreen;
    border-radius: 12px;
box-shadow: 1px 1px 1px $preno-shadow-color;
}
.plus:hover, .minus:hover{
box-shadow: 0px 0px 0px;
}
.preno__input__numbers{
    border: 2px solid $preno-color-lightgreen;
    text-align: center;
    padding: .4em;
    border-radius: 5px;
font-size: 0.9rem;
    color: $preno-fontcolor;
background-color: $preno-bgcolor;
}


/* BUTTONs */

.choise__adults{
    padding: 0.8em;
    border-bottom: 1px solid $preno-color-lightgreen;;
    color: $preno-fontcolor;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 0.8em;
}


/* CALENDAR VIEW */
.preno__calendar{
    order:1;
    width: 100%;
    min-height: $min-height-preno;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0 20px;
    display: none;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }

}
.preno__calendar h2{
    color: $preno-color-lightgreen;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
} 
/* HOURS VIEW */
.preno__hours{
    order:1;
    width: 100%;
    min-height: $min-height-preno;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0 20px;
    display: none;
    position: relative;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }

    & .prenot__button_done{
        margin: 0px;
        width: 100%;
    }
}
.preno__hours h2{
    color: $preno-color-lightgreen;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
} 
.preno__hours h3{
    color: $preno-color-lightgreen;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    padding-top: .8em;
    padding-bottom: .4em;
    padding-left: .8em;
    margin: 0;
} 
.preno__hours .hours_container{
    width: 100%;
    overflow: hidden;
    margin: .8em;
    padding-top: 0;
    margin-top: 0;
    display: flex;
    justify-content:flex-start;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.8em;
}
.preno__hours .hours_container .houritem.havailable{

    border-radius: 5px;
    border: 1px solid  $preno-color-lightgreen;
    color: $preno-fontcolor;
    padding: .4em;
    cursor: pointer;
}
.preno__hours .hours_container .houritem.havailable:hover{
    
    border: 1px solid  $preno-font-greenbg;
    color: $preno-font-greenbg;
     background-color: $preno-color-lightgreen;
}
.preno__hours .hours_container .houritem.full{

    border-radius: 5px;
    border: 1px solid  $preno-color-lightgreen;
    background-color: $preno-bgcolor-disabled;
    padding: .4em;
    cursor:not-allowed;
    color: $preno-fontcolor;
}




@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}
