.banner-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
    margin-bottom: 1rem;
    gap: 1rem;
width: 100%;
}

.banner{
    background-repeat: no-repeat;
        position: relative;
        background-position: center;
    min-height: 100px;
width: 100%;
    border-radius: 4px;
    border: 1px solid #444c48;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.banner h3{
    color: #fff;
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
}
@media only screen and (max-width: 600px) {
.banner-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
}
.banner h3{
    font-size: 1.8rem;
    font-weight: 500;
}

}
.banner-products{
    background-image: url(./../assets/img/prodotti_banner.jpg);
}
.banner-producers{
    background-image: url(./../assets/img/produttori_banner.jpg);
}
.banner-menu{
    background-image: url(./../assets/img/cucinajanakim.jpg);
}