$form-check-bgcolor-checked: #000000;
$form-check-bgcolor-unchecked: #fafafa;
$form-check-bgcolor-hover: #FFFFFF;

$modal-border: #8D8F47;
$modal-x: #dde06d;
$modal-x-hover: #8D8F47;

.modal,.modal-autogen {
    display: none;
    position: fixed; 
    padding: 1rem;
    padding-top: 120px;
    left: 0; 
    top: 0;
    width: 100%;
    height: 100%; 
    z-index: 999;
    background-color: rgba(#000000, 0.5);

    &.show{
        display: block;    
    }
    & .modal-container{
        width: 100%;  
        background-color: #FFFFFF;
        border-radius: 5px;
        margin: auto; 
        z-index: 1000;
        position: relative; 
        box-shadow: 0.1rem 1rem 2rem rgba(#000000, 0.7);;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        border: 3px solid $modal-border;
        @media only screen and (min-width: 768px) {
            max-width: 500px;
        }
        
        & .modal-header {
            position: relative;
            padding: 20px;
            padding-left: 20px;
            border-bottom: 1px solid #8a8a8a85;
            color: $modal-border;
            font-weight: 600;
            font-size: 1.3em;
            & .close-btn {
                position: absolute;
                right: 1rem;
                top: .5rem;
                color: $modal-border; 
                font-size: 32px;  
                font-weight: bold;
                cursor: pointer;
            }
            & .close-btn:hover {
                color: $modal-x-hover;
            }
        }
        
        & .modal-content {
            padding: 20px;
            padding-left: 20px;
            border-bottom: 1px solid #8a8a8a85;
            font-size: 1.0rem;
            line-height: 1.5em;
    max-height: calc(100vh - 60vh);
            overflow-x: hidden;
        }
        
        & .modal-footer {
            padding: 20px;
            padding-left: 20px;
            text-align: right;
            & .modal-btn-close{
                padding:.8em;
                padding-top:.6em;
                padding-bottom:.6em;
                font-size: 1.0em;
                background-color: #797979;
                color: #FFFFFF;
                border: 0px;
                border-radius: 3px;
                cursor: pointer;
                &:hover{
                    background-color: #585858;

                }
            }
        }
    }
}
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }