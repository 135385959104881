@import "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,400;0,500;0,600;0,700;0,900;1,300&display=swap";
@font-face {
  font-family: MuseoCyrl500;
  src: url("MuseoCyrl500.31565f15.woff2") format("woff2"), url("MuseoCyrl500.327707ef.woff") format("woff");
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

body {
  scroll-behavior: smooth;
  background-color: #fff;
  font-family: Raleway, Arial, Helvetica;
  font-size: 16px;
  overflow-x: hidden;
}

.header {
  z-index: 998;
  background-color: #c89fa5;
  border-bottom: 1px solid #c89fa5;
  width: 100vw;
  position: fixed;
  box-shadow: 1px 6px 14px -3px #00000096;
}

.navbar {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.hamburger {
  display: none;
}

.bar {
  background-color: #fff;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  transition: all .3s ease-in-out;
  display: block;
}

.nav-menu {
  justify-content: space-between;
  align-items: center;
  margin-right: 1em;
  display: flex;
}

.nav-menu-onindex {
  display: none;
}

.nav-item {
  margin-left: 3rem;
  position: relative;
}

.langitem {
  display: none;
}

@media only screen and (width <= 1100px) {
  .langitem {
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
  }
}

.langitem a, .langitem a:hover, .langitem a.active {
  color: #fff;
}

.lang-select {
  margin-left: 2rem;
  position: relative;
}

@media only screen and (width <= 1100px) {
  .lang-select {
    margin-bottom: 2rem;
    margin-left: 0;
    display: none;
  }
}

.nav-link {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}

.nav-link:hover, .nav-link.active {
  color: #fff;
}

.nav-logo {
  color: #000;
  text-transform: uppercase;
  font-family: MuseoCyrl500, Arial, Helvetica;
  font-size: 1.9rem;
  font-weight: 500;
}

@media only screen and (width <= 785px) {
  .nav-logo {
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 1100px) {
  .nav-logo {
    font-size: 1.3rem;
  }

  .nav-menu {
    text-align: center;
    background-color: #c89fa5;
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    transition: all .3s;
    position: fixed;
    top: 3.5rem;
    left: -100%;
    box-shadow: 0 10px 27px #0000000d;
  }

  .nav-menu-onindex {
    display: block;
    top: 0;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem 0;
  }

  .nav-link {
    color: #fff;
  }

  .hamburger {
    cursor: pointer;
    color: #fff;
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
}

@media only screen and (width <= 1100px) and (width <= 320px) {
  .nav-logo {
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 1100px) {
  .hamburger.active .bar:first-child {
    transform: translateY(8px)rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px)rotate(-45deg);
  }
}

.nav-item:after {
  content: "";
  transform-origin: 100%;
  background: #8d8f47;
  width: 100%;
  height: 3px;
  margin-top: .2em;
  transition: transform .5s;
  position: absolute;
  top: 100%;
  left: 0;
  transform: scaleX(0);
}

.nav-item:hover {
  color: #fff;
}

.nav-item:hover:after {
  transform-origin: 0;
  transform: scaleX(1);
}

.nav-item.active {
  color: #fff;
  font-weight: 500;
}

.nav-item.active:after {
  content: "";
  transform-origin: 0;
  background: #8d8f47;
  width: 100%;
  height: 3px;
  margin-top: .2em;
  position: absolute;
  top: 100%;
  left: 0;
  transform: scaleX(1);
}

.main {
  letter-spacing: .05em;
  background-color: #f8f8ff;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 150px;
  padding: 2em 1em;
  font-size: 1.1em;
  line-height: 1.5em;
  display: flex;
}

.main .container {
  width: 100%;
  max-width: 1000px;
  min-height: 150px;
}

.main .container p {
  text-align: justify;
}

.main .container h2 {
  margin-bottom: .4em;
  font-size: 1.8em;
}

@media only screen and (width <= 768px) {
  .main .container h2 {
    font-size: 1.4em;
  }
}

.imgmaintext {
  border: 1px solid #9b9b9b;
  border-radius: 5px;
  width: 40%;
  margin-top: .3em;
  margin-bottom: 1em;
  display: inline;
}

@media only screen and (width <= 768px) {
  .imgmaintext {
    width: 100%;
  }
}

.imgmainr {
  float: right;
  margin-left: 1.5em;
}

.imgmainl {
  float: left;
  margin-right: 1.5em;
}

.footer {
  color: #000;
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#444c48", endColorstr= "#ffcccc", GradientType= 0);
  background: linear-gradient(#444c48 0%, #c89fa5 0%, #fcc 100%);
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 1em;
  display: flex;
}

.footer a {
  color: #2a2b16;
}

.footer a:hover {
  color: #4b4d29;
}

.footer .container {
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 1000px;
  min-height: 100px;
  display: flex;
}

.footer div div:first-child b {
  font-size: 16px;
  line-height: 30px;
}

.footer div div:first-child ul li {
  line-height: 25px;
}

.footer div div:first-child {
  width: 34%;
  font-size: .9em;
  line-height: 1.8em;
}

.footer div div:nth-child(2) {
  text-align: center;
  width: 33%;
  font-size: .9em;
  line-height: 1.5em;
}

.footer div div:nth-child(3) {
  text-align: right;
  width: 33%;
  font-size: .9em;
  line-height: 1.5em;
}

.social-icon {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
}

.social-icon a {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.social-icon a img {
  width: 32px;
  height: auto;
  margin-left: .5rem;
  margin-right: .5rem;
}

.social-icon a img:hover {
  color: #fff;
}

@media only screen and (width <= 768px) {
  .footer div div:first-child {
    width: 100%;
  }

  .footer div div:nth-child(2), .footer div div:nth-child(3) {
    text-align: left;
    width: 100%;
    margin-top: 1em;
  }

  .footer .container {
    flex-flow: column wrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    max-width: 1000px;
    min-height: 150px;
    display: flex;
  }

  .social-icon {
    justify-content: start;
  }

  .social-icon a img {
    margin-left: 0;
    margin-right: 1rem;
  }
}

.gmap {
  padding-top: 75px;
}

.dovesiamo {
  padding-bottom: 5em;
}

.dovesiamo h3 {
  padding-bottom: .8em;
}

.dovesiamo h1 {
  padding-bottom: .5em;
}

@media only screen and (width <= 768px) {
  .dovesiamo h1 {
    font-size: 1.4em;
  }
}

.dovesiamo input {
  color: #474b4f;
  border: 1px solid #60892fc5;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1em;
  padding: .6em;
  font-size: .9rem;
}

.dovesiamo button {
  color: #fff;
  background-color: #474b4f;
  border: 2px solid #60892fc5;
  border-radius: 5px;
  width: 100%;
  padding: .6em;
  font-size: .9rem;
}

.dovesiamo .datiristorante {
  line-height: 28px;
}

.dovesiamo #map_canvas {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 30em;
}

.ristomenu h1 {
  padding-bottom: .5em;
}

@media only screen and (width <= 768px) {
  .ristomenu h1 {
    font-size: 1.4em;
  }
}

.home-header {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 17rem;
  padding: 1rem;
  display: flex;
}

.home-header-logo {
  color: #000;
  text-transform: uppercase;
  margin-top: .6em;
  margin-left: .2em;
  font-family: MuseoCyrl500, Arial, Helvetica;
  font-size: 3rem;
  font-weight: 500;
  line-height: 50px;
}

@media only screen and (width <= 785px) {
  .home-header-logo {
    margin-top: 1em;
    font-size: 2rem;
    font-weight: 500;
    line-height: 35px;
  }
}

@media only screen and (width <= 400px) {
  .home-header-logo {
    margin-top: 1.8rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 25px;
  }
}

.home-header-logo-link {
  font-size: 1rem;
  font-weight: normal;
  line-height: 2rem;
  overflow: hidden;
}

.home-header-logo-link a {
  color: #8d8f47;
  font-size: 1.5rem;
  font-weight: 600;
  overflow: hidden;
}

.home-header-logo-link a:first-of-type {
  margin-right: 1rem;
}

.home-header-logo-link a:last-of-type {
  margin-left: 1rem;
}

@media only screen and (width <= 785px) {
  .home-header-logo-link a {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  .home-header-logo-link a:first-of-type {
    margin-right: .2rem;
  }

  .home-header-logo-link a:last-of-type {
    margin-left: .2rem;
  }
}

@media only screen and (width <= 400px) {
  .home-header-logo-link a {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .home-header-logo-link a:first-of-type {
    margin-right: .2rem;
  }

  .home-header-logo-link a:last-of-type {
    margin-left: .2rem;
  }
}

.home-header-logo-link a:hover {
  text-decoration: underline;
}

.home-header img {
  max-height: 9em;
}

@media only screen and (width <= 785px) {
  .home-header img {
    max-height: 6em;
  }
}

@media only screen and (width <= 400px) {
  .home-header img {
    max-height: 4em;
  }
}

.undernav {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 450px;
  padding: 20px 0;
  display: flex;
}

@media only screen and (width <= 768px) {
  .undernav {
    justify-content: stretch;
    width: 100%;
    min-height: 300px;
    padding: 0;
  }
}

.undernav_home {
  background-color: #fff;
  background-image: url("_29A9446.7ad6478e.webp");
  width: 100vw;
  min-height: 80vh;
  margin: auto auto 0;
}

.undernav_menu_page {
  background-image: url("cucinajanakim.b5b0cb4b.jpeg");
}

.undernav_dove_page {
  background-image: url("BRACE-2018-120dpi-50copia.cf4dfc78.jpeg");
}

.undernav_wine {
  background-image: url("BRACE-2018-120dpi-19.c74b73f8.jpeg");
}

.undernav_cancel {
  background-image: url("osteria-dovesiamo.db3f0491.jpeg");
}

.undernav_osteria {
  background-image: url("BRACE-2018-120dpi-22.7dd8a524.d6116ea1.jpeg");
}

.undernav_prodotti {
  background-image: url("prodotti_osteria.2b151e0e.jpeg");
}

.undernav_produttori {
  background-image: url("produttori.93edf89f.jpeg");
}

.preno {
  background-image: url("osteria_prenota.ff1412cb.6a69c5fe.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 600px;
  padding: 20px 0;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 768px) {
  .preno {
    justify-content: stretch;
    width: 100%;
    min-height: 495px;
    padding: 0;
  }
}

.preno h1 {
  color: #000;
  text-align: center;
  margin: 0;
  padding: .4em;
  font-size: 22px;
}

@media only screen and (width <= 320px) {
  .preno h1 {
    font-size: 18px;
  }
}

.preno__topinfo {
  text-align: center;
  color: #000;
  font-size: 10px;
}

.preno__container {
  background-color: #d6cbb4e3;
  border: 1px solid #8d8f47;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  margin-top: 85px;
  margin-left: 5px;
  margin-right: 5px;
  padding: .8em;
  animation: 2s fadein;
  display: flex;
}

@media only screen and (width <= 768px) {
  .preno__container {
    background-color: #d6cbb4e3;
    border: 0 solid #000;
    border-radius: 0;
    width: 100%;
    margin-top: 0;
    padding-top: 85px;
  }
}

.preno__summaryreq {
  color: #000;
  letter-spacing: .02em;
  min-height: 495px;
  padding-top: 1.8em;
  line-height: 1.6em;
  display: none;
  position: relative;
}

.preno__summaryreq h2 {
  color: #8d8f47;
  text-align: center;
  margin-bottom: .8em;
  font-size: 18px;
  font-weight: 600;
}

.preno__summaryreq .summary {
  text-align: center;
  margin-bottom: 1.8em;
}

.preno__summaryreq .summaryshow {
  display: none;
}

.preno__datareq {
  color: #000;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .8em;
  width: 100%;
  min-height: 495px;
  padding-top: 1.8em;
  display: none;
  position: relative;
}

.preno__datareq h2 {
  color: #8d8f47;
  text-align: center;
  margin-bottom: .8em;
  font-size: 18px;
  font-weight: 600;
}

.preno__datareq_data div {
  color: #000;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .2em;
  padding-bottom: .8em;
  display: flex;
}

.preno__datareq_data .preno__input {
  color: #000;
  background-color: #d6cbb4e3;
  border: 1px solid #8d8f47;
  border-radius: 5px;
  width: 100%;
  padding: .6em;
  font-size: .9rem;
}

.preno__datareq_data .othercheck {
  margin-top: 1em;
}

.preno__datareq_data .checkboxcont {
  font-size: .8em;
}

.preno__intolerances {
  color: #000;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .8em;
  width: 100%;
  min-height: 495px;
  padding-top: 1.8em;
  display: none;
  position: relative;
}

.preno__intolerances h2 {
  color: #8d8f47;
  text-align: center;
  margin-bottom: .8em;
  font-size: 18px;
  font-weight: 600;
}

.preno__intolerances .rowIntolerance {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .8em;
  display: flex;
}

.preno__intolerances .rowIntolerance .txtperson {
  width: 100px;
  max-width: 100px;
}

.preno__intolerances .rowIntolerance .selectperson {
  flex-grow: 1;
  padding-left: .4em;
  padding-right: .4em;
}

.preno__intolerances .listIntolerance {
  margin-bottom: .8em;
  margin-left: 93px;
  font-size: .8em;
  line-height: 20px;
}

@media only screen and (width <= 768px) {
  .preno__intolerances .listIntolerance {
    margin-left: 113px;
    padding-left: .4em;
    padding-right: .4em;
  }
}

@media only screen and (width <= 380px) {
  .preno__intolerances .listIntolerance {
    margin-left: 103px;
    padding-left: .4em;
    padding-right: .4em;
  }
}

@media only screen and (width <= 320px) {
  .preno__intolerances .listIntolerance {
    margin-left: 88px;
    padding-left: .4em;
    padding-right: .4em;
  }
}

.addinto {
  padding: 0;
  font-size: 1em;
}

.adultschildrenlist {
  height: 100%;
  max-height: 370px;
  padding-right: 5px;
  overflow: hidden auto;
}

.preno__main {
  order: 1;
  width: 100%;
  min-height: 495px;
  position: relative;
}

@media only screen and (width <= 768px) {
  .preno__main {
    width: 100%;
  }
}

.preno__main .rowdiv {
  color: #000;
  cursor: pointer;
  border-bottom: 1px solid #8d8f47;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .8em;
  padding: .8em;
  display: flex;
}

.preno__main .rowdiv span:first-child, .preno__main .rowdiv label {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.preno__main .rowdiv span:first-child img {
  margin-right: .8em;
}

.preno__main .rowdiv span:nth-child(2) {
  font-weight: 700;
}

.preno__main .checkboxcontainer.first {
  border-bottom: 0 solid;
  margin-top: 1em;
}

.preno__main .checkboxcontainer.second {
  border-bottom: 0 solid;
}

.preno__main .checkboxcontainer.houseguestSelHouse {
  border-bottom: 0 solid;
  width: 100%;
  padding-left: .8rem;
  padding-right: .8rem;
  display: none;
}

.preno__main .checkboxcontainer.houseguestSelHouse .apartamentsContainer .addapart {
  text-align: center;
  color: #fff;
  padding: .5rem .8rem;
  font-size: 1.8rem;
  line-height: 1rem;
}

.preno__main .checkboxcontainer.houseguestSelHouse .apartamentsContainer .selapart {
  width: 100%;
}

.preno__main .checkboxcontainer.houseguestSelHouse #resAps {
  flex-flow: wrap;
  align-items: center;
  width: 100%;
  padding-top: .5rem;
  display: flex;
}

.preno__main .checkboxcontainer.houseguestSelHouse #resAps .apsName {
  color: #fff;
  cursor: pointer;
  background-color: #8d8f47;
  border: 1px solid #ccc;
  border-radius: .3rem;
  margin-right: .5rem;
  padding: .3rem;
  font-size: .8rem;
}

.preno__main .checkboxcontainer.houseguestSelHouse #resAps .apsName:before {
  content: "X";
  color: #bbbaba;
  margin-right: .5rem;
  font-size: .8rem;
  font-weight: bold;
}

.preno__choise {
  flex-direction: column;
  order: 1;
  justify-content: space-between;
  min-height: 495px;
  display: none;
  position: relative;
}

@media only screen and (width <= 768px) {
  .preno__choise {
    width: 100%;
  }
}

.preno__choise .prenot__button_done {
  width: 100%;
  margin: 0;
}

.choise_select {
  width: 100%;
}

@media only screen and (width <= 768px) {
  .choise_select {
    width: 100%;
  }
}

.choise__whitnumber {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: .8em;
  display: flex;
}

.choise__whitnumber div img {
  margin-right: .8em;
}

.choise__whitnumber div:first-child {
  text-align: left;
  flex-direction: row;
  align-items: center;
  width: 100px;
  display: flex;
}

.plus, .minus {
  cursor: pointer;
  background: radial-gradient(5px -9px, circle, #8d8f47 8%, #d6cbb4e3 26px);
  background-color: #d6cbb4e3;
  border: 2px solid #8d8f47;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  box-shadow: 1px 1px 1px #000;
}

.plus:hover, .minus:hover {
  box-shadow: 0 0;
}

.preno__input__numbers {
  text-align: center;
  color: #000;
  background-color: #d6cbb4e3;
  border: 2px solid #8d8f47;
  border-radius: 5px;
  padding: .4em;
  font-size: .9rem;
}

.choise__adults {
  color: #000;
  border-bottom: 1px solid #8d8f47;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .8em;
  padding: .8em;
  display: flex;
}

.preno__calendar {
  flex-direction: column;
  order: 1;
  justify-content: space-between;
  width: 100%;
  min-height: 495px;
  padding: 20px 0;
  display: none;
}

@media only screen and (width <= 768px) {
  .preno__calendar {
    width: 100%;
  }
}

.preno__calendar h2 {
  color: #8d8f47;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.preno__hours {
  flex-direction: column;
  order: 1;
  justify-content: space-between;
  width: 100%;
  min-height: 495px;
  padding: 20px 0;
  display: none;
  position: relative;
}

@media only screen and (width <= 768px) {
  .preno__hours {
    width: 100%;
  }
}

.preno__hours .prenot__button_done {
  width: 100%;
  margin: 0;
}

.preno__hours h2 {
  color: #8d8f47;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.preno__hours h3 {
  color: #8d8f47;
  margin: 0;
  padding: .8em 0 .4em .8em;
  font-size: 14px;
  font-weight: 600;
}

.preno__hours .hours_container {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: .8em;
  width: 100%;
  margin: 0 .8em .8em;
  padding-top: 0;
  display: flex;
  overflow: hidden;
}

.preno__hours .hours_container .houritem.havailable {
  color: #000;
  cursor: pointer;
  border: 1px solid #8d8f47;
  border-radius: 5px;
  padding: .4em;
}

.preno__hours .hours_container .houritem.havailable:hover {
  color: #222629;
  background-color: #8d8f47;
  border: 1px solid #222629;
}

.preno__hours .hours_container .houritem.full {
  cursor: not-allowed;
  color: #000;
  background-color: #8d8f47b9;
  border: 1px solid #8d8f47;
  border-radius: 5px;
  padding: .4em;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

input:-webkit-autofill {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

input:-webkit-autofill:hover {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

input:-webkit-autofill:focus {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

textarea:-webkit-autofill {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

textarea:-webkit-autofill:hover {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

textarea:-webkit-autofill:focus {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

select:-webkit-autofill {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

select:-webkit-autofill:hover {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

select:-webkit-autofill:focus {
  color: #000;
  -webkit-text-fill-color: #000;
  background-color: #968e7e;
  border: 1px solid #fff;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #968e7e;
}

textarea:focus, input:focus {
  outline: none;
}

.custom-radio {
  flex-flow: row;
  justify-content: right;
  align-items: stretch;
  display: flex;
}

.custom-radio div {
  white-space: nowrap;
  margin-left: .8rem;
}

.custom-control-label:before {
  cursor: pointer;
  background-color: #000000a1;
  border-color: #968e7ecc;
}

.custom-radio .custom-control-input:focus ~ .custom-control-label:before {
  cursor: pointer;
  border-color: #968e7ecc;
  outline: 0;
  box-shadow: inset 0 1px 1px #000000a1, 0 0 8px #968e7ecc;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #000000a1;
  border-color: #fafafacc;
}

.checkboxcont {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  min-height: 20px;
  padding-right: 35px;
  font-size: .9em;
  display: block;
  position: relative;
}

.checkboxcont input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.checkmark {
  background-color: #fafafa;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.checkboxcont:hover input ~ .checkmark {
  background-color: #fafafa;
  transition: all .5s linear;
}

.checkboxcont input:checked ~ .checkmark {
  background-color: #968e7e;
  transition: all .5s linear;
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.checkboxcont input:checked ~ .checkmark:after {
  display: block;
}

.checkboxcont .checkmark:after {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 5px;
  height: 10px;
  top: 4px;
  left: 8px;
  transform: rotate(45deg);
}

select {
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
  background-color: #8d8f47;
  background-image: linear-gradient(#50522a, #50522a), linear-gradient(-135deg, #0000 50%, #8d8f47 50%), linear-gradient(-225deg, #0000 50%, #8d8f47 50%), linear-gradient(#8d8f47 42%, #50522a 42%);
  background-position: right 20px center, 100% 100%, 100% 100%, 100% 100%;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  border: 1px solid #50522a;
  border-radius: .2em;
  outline: 0;
  width: 100%;
  padding: .65em 2.5em .55em .75em;
  line-height: 1;
}

select:hover {
  background-image: linear-gradient(#50522a, #50522a), linear-gradient(-135deg, #0000 50%, #50522a 50%), linear-gradient(-225deg, #0000 50%, #50522a 50%), linear-gradient(#50522a 42%, #8d8f47 42%);
}

select:active {
  color: #8d8f47;
  background-color: #50522a;
  background-image: linear-gradient(#50522a, #50522a), linear-gradient(-135deg, #0000 50%, #50522a 50%), linear-gradient(-225deg, #0000 50%, #50522a 50%), linear-gradient(#50522a 42%, #8d8f47 42%);
  border-color: #50522a;
}

.calendar {
  color: #000;
  width: 100%;
  min-height: 200px;
  padding: .4em;
}

.calendar-table {
  width: 100%;
  transition: all .5s;
}

.calendar-table thead tr th:first-child {
  text-align: center;
  font-weight: 400;
}

.calendar-table thead tr th:nth-child(2) {
  text-align: center;
  font-weight: 700;
}

.calendar-table thead tr th:nth-child(3) {
  text-align: center;
  font-weight: 400;
}

.monthcontrol {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 30px;
  padding: .5em 4px;
  font-size: 18px;
  font-weight: 600;
}

.daysname {
  text-align: center;
  width: 14.28%;
  padding-top: 1.8em;
  padding-bottom: .8em;
  font-size: 12px;
  font-weight: 500;
}

.daynumber {
  text-align: center;
  border: 1px solid #0000;
  padding-top: .8em;
  padding-bottom: .8em;
  font-size: 16px;
  transition: all .3s;
}

.daynumber.active:hover {
  cursor: pointer;
  background-color: #bdbdbd;
  border: 1px solid #395711;
}

.daynumber.noactive {
  color: #d6cbb4e3;
  cursor: not-allowed;
}

.daynumber.nodispo {
  color: #fff;
  cursor: not-allowed;
  background-color: #395711;
}

.daynumber.nosamemonth {
  color: #8d8f47;
  cursor: pointer;
}

.daynumber.selected {
  cursor: pointer;
  background-color: #bdbdbd;
  border: 1px solid #395711;
}

.legendRow {
  color: #000;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  padding: .6em;
  font-size: .8em;
  display: flex;
}

.completoOchiuso {
  background-color: #395711;
  width: 20px;
  height: 20px;
}

.giornoSelezionatoOoggi {
  background-color: #bdbdbd;
  border: 1px solid #395711;
  width: 20px;
  height: 20px;
}

.giornoDisponibile {
  background-color: #d6cbb4;
  border: 1px solid #000;
  width: 20px;
  height: 20px;
}

.disable-select {
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.lds-ellipsis {
  width: 80px;
  height: 80px;
  margin: 30px auto auto;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ellipsis div {
  background: #fff;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 33px;
}

.lds-ellipsis div:first-child {
  animation: .6s infinite lds-ellipsis1;
  left: 8px;
}

.lds-ellipsis div:nth-child(2) {
  animation: .6s infinite lds-ellipsis2;
  left: 8px;
}

.lds-ellipsis div:nth-child(3) {
  animation: .6s infinite lds-ellipsis2;
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  animation: .6s infinite lds-ellipsis3;
  left: 56px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

.preno_btn_onbottom {
  width: 100%;
  position: absolute;
  bottom: .8em;
}

.prenot__button_done {
  text-align: center;
  color: #000;
  cursor: pointer;
  background-color: #8d8f47;
  border-radius: 5px;
  gap: .4em;
  margin: 1.8em .4em .4em;
  padding: .8em;
  font-weight: 700;
}

.preno__buttons {
  flex-direction: row;
  align-items: stretch;
  gap: .4em;
  display: flex;
}

.preno__buttons__generic {
  text-align: center;
  color: #222629;
  cursor: pointer;
  background-color: #8d8f47;
  border-radius: .2em;
  padding: .4em .8em;
  font-weight: 700;
}

.preno__buttons__back {
  text-align: center;
  color: #d8d8d8;
  cursor: pointer;
  text-shadow: 0 1px #727272;
  background-color: #8d8f47b9;
  border-radius: 5px;
  width: 100%;
  padding: .8em;
  font-weight: 700;
}

.preno__buttons__back:before {
  content: "«";
  margin-right: .8em;
  font-size: .9rem;
}

.preno__buttons__next {
  color: #fff;
  text-align: center;
  cursor: pointer;
  text-shadow: 1px 1px #000;
  background-color: #8d8f47;
  border-radius: .2em;
  width: 100%;
  padding: .8em;
  font-weight: 700;
}

.preno__buttons__next:after {
  content: "»";
  margin-left: .8em;
  font-size: .9rem;
}

::-webkit-scrollbar {
  width: 10px;
  padding-right: 3px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
  box-shadow: inset 0 0 6px #8d8d8d4d;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px #8d8d8d4d;
  background: #c0c0c0cc;
  border-radius: 10px;
  width: 50%;
  -webkit-box-shadow: inset 0 0 6px #8d8d8d80;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ddd6;
}

.modal, .modal-autogen {
  z-index: 999;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  padding: 120px 1rem 1rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.modal.show, .modal-autogen.show {
  display: block;
}

.modal .modal-container, .modal-autogen .modal-container {
  z-index: 1000;
  background-color: #fff;
  border: 3px solid #8d8f47;
  border-radius: 5px;
  width: 100%;
  margin: auto;
  animation-name: animatetop;
  animation-duration: .4s;
  position: relative;
  box-shadow: .1rem 1rem 2rem #000000b3;
}

@media only screen and (width >= 768px) {
  .modal .modal-container, .modal-autogen .modal-container {
    max-width: 500px;
  }
}

.modal .modal-container .modal-header, .modal-autogen .modal-container .modal-header {
  color: #8d8f47;
  border-bottom: 1px solid #8a8a8a85;
  padding: 20px;
  font-size: 1.3em;
  font-weight: 600;
  position: relative;
}

.modal .modal-container .modal-header .close-btn, .modal-autogen .modal-container .modal-header .close-btn {
  color: #8d8f47;
  cursor: pointer;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  top: .5rem;
  right: 1rem;
}

.modal .modal-container .modal-header .close-btn:hover, .modal-autogen .modal-container .modal-header .close-btn:hover {
  color: #8d8f47;
}

.modal .modal-container .modal-content, .modal-autogen .modal-container .modal-content {
  border-bottom: 1px solid #8a8a8a85;
  max-height: 40vh;
  padding: 20px;
  font-size: 1rem;
  line-height: 1.5em;
  overflow-x: hidden;
}

.modal .modal-container .modal-footer, .modal-autogen .modal-container .modal-footer {
  text-align: right;
  padding: 20px;
}

.modal .modal-container .modal-footer .modal-btn-close, .modal-autogen .modal-container .modal-footer .modal-btn-close {
  color: #fff;
  cursor: pointer;
  background-color: #797979;
  border: 0;
  border-radius: 3px;
  padding: .6em .8em;
  font-size: 1em;
}

.modal .modal-container .modal-footer .modal-btn-close:hover, .modal-autogen .modal-container .modal-footer .modal-btn-close:hover {
  background-color: #585858;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.dropdown {
  position: relative;
}

@media only screen and (width <= 1100px) {
  .dropdown {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.dropdown .dropdown-content {
  color: #fff;
  z-index: 1;
  background-color: #c89fa5;
  min-width: 130px;
  padding: .8em;
  font-size: .9em;
  display: none;
  position: absolute;
  top: 34px;
  right: -30px;
}

.dropdown .dropdown-content a {
  color: #fff;
  cursor: pointer;
  padding: .8em;
  line-height: 40px;
}

.dropdown .dropdown-content a img {
  margin-right: 10px;
}

.dropdown .dropdown-content a:hover {
  color: #adff2f;
}

@media only screen and (width <= 1100px) {
  .dropdown .dropdown-content {
    text-align: center;
    width: 100%;
    padding: 1rem 0;
    position: static;
    right: 0;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.tabs {
  width: 100%;
}

.tabs .tab-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.tabs .tab-header > div {
  text-align: center;
  color: #888;
  cursor: pointer;
  text-transform: uppercase;
  width: 25%;
  font-size: 14px;
  font-weight: 600;
}

.tabs .tab-header > div.active {
  color: #8d8f47;
}

.tabs .tab-indicator {
  background-color: #8d8f47;
  border-radius: 5px;
  width: 25%;
  height: 5px;
  margin-top: 5px;
  transition: all .5s ease-in-out;
  position: relative;
  left: 0;
}

.tabs .tab-body {
  padding: 10px 0;
  position: relative;
}

.tabs .tab-body > .tab {
  opacity: 0;
  margin-top: 5px;
  transition: opacity .2s ease-in-out, transform .5s ease-in-out;
  display: none;
  transform: scale(.9);
}

.tabs .tab-body > .tab.active {
  opacity: 1;
  display: block;
  transform: scale(1);
}

.banner-container {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: grid;
}

.banner {
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #444c48;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 20px;
  display: flex;
  position: relative;
}

.banner h3 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #00000080;
  font-size: 2rem;
  font-weight: 700;
}

@media only screen and (width <= 600px) {
  .banner-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    display: grid;
  }

  .banner h3 {
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.banner-products {
  background-image: url("prodotti_banner.8bb29163.jpeg");
}

.banner-producers {
  background-image: url("produttori_banner.490679f9.jpeg");
}

.banner-menu {
  background-image: url("cucinajanakim.b5b0cb4b.jpeg");
}

.row {
  box-sizing: border-box;
  flex-flow: wrap;
  flex: 0 auto;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 1rem;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 1rem;
}

.col-xs-1 {
  box-sizing: border-box;
  flex-basis: 8.33333%;
  max-width: 8.33333%;
  padding: 1rem;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-2 {
  box-sizing: border-box;
  flex-basis: 16.6667%;
  max-width: 16.6667%;
  padding: 1rem;
}

.col-xs-offset-2 {
  margin-left: 16.6667%;
}

.col-xs-3 {
  box-sizing: border-box;
  flex-basis: 25%;
  max-width: 25%;
  padding: 1rem;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-4 {
  box-sizing: border-box;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
  padding: 1rem;
}

.col-xs-offset-4 {
  margin-left: 33.3333%;
}

.col-xs-5 {
  box-sizing: border-box;
  flex-basis: 41.6667%;
  max-width: 41.6667%;
  padding: 1rem;
}

.col-xs-offset-5 {
  margin-left: 41.6667%;
}

.col-xs-6 {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 1rem;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-7 {
  box-sizing: border-box;
  flex-basis: 58.3333%;
  max-width: 58.3333%;
  padding: 1rem;
}

.col-xs-offset-7 {
  margin-left: 58.3333%;
}

.col-xs-8 {
  box-sizing: border-box;
  flex-basis: 66.6667%;
  max-width: 66.6667%;
  padding: 1rem;
}

.col-xs-offset-8 {
  margin-left: 66.6667%;
}

.col-xs-9 {
  box-sizing: border-box;
  flex-basis: 75%;
  max-width: 75%;
  padding: 1rem;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-10 {
  box-sizing: border-box;
  flex-basis: 83.3333%;
  max-width: 83.3333%;
  padding: 1rem;
}

.col-xs-offset-10 {
  margin-left: 83.3333%;
}

.col-xs-11 {
  box-sizing: border-box;
  flex-basis: 91.6667%;
  max-width: 91.6667%;
  padding: 1rem;
}

.col-xs-offset-11 {
  margin-left: 91.6667%;
}

.col-xs-12 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 1rem;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (width >= 41rem) {
  .col-sm {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }

  .col-sm-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 1rem;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 1rem;
  }

  .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 1rem;
  }

  .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 1rem;
  }

  .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 1rem;
  }

  .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 1rem;
  }

  .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 1rem;
  }

  .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  .col-sm-11 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 1rem;
  }

  .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  .col-sm-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .row.start-sm {
    justify-content: flex-start;
  }

  .row.center-sm {
    justify-content: center;
  }

  .row.end-sm {
    justify-content: flex-end;
  }

  .row.top-sm {
    align-items: flex-start;
  }

  .row.middle-sm {
    align-items: center;
  }

  .row.bottom-sm {
    align-items: flex-end;
  }

  .row.around-sm {
    justify-content: space-around;
  }

  .row.between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (width >= 65rem) {
  .col-md {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }

  .col-md-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 1rem;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-2 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 1rem;
  }

  .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  .col-md-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-4 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 1rem;
  }

  .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  .col-md-5 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 1rem;
  }

  .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  .col-md-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-7 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 1rem;
  }

  .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  .col-md-8 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 1rem;
  }

  .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  .col-md-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-10 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 1rem;
  }

  .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  .col-md-11 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 1rem;
  }

  .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  .col-md-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .row.start-md {
    justify-content: flex-start;
  }

  .row.center-md {
    justify-content: center;
  }

  .row.end-md {
    justify-content: flex-end;
  }

  .row.top-md {
    align-items: flex-start;
  }

  .row.middle-md {
    align-items: center;
  }

  .row.bottom-md {
    align-items: flex-end;
  }

  .row.around-md {
    justify-content: space-around;
  }

  .row.between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (width >= 91rem) {
  .col-lg {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }

  .col-lg-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 1rem;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 1rem;
  }

  .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 1rem;
  }

  .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 1rem;
  }

  .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 1rem;
  }

  .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 1rem;
  }

  .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 1rem;
  }

  .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  .col-lg-11 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 1rem;
  }

  .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  .col-lg-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .row.start-lg {
    justify-content: flex-start;
  }

  .row.center-lg {
    justify-content: center;
  }

  .row.end-lg {
    justify-content: flex-end;
  }

  .row.top-lg {
    align-items: flex-start;
  }

  .row.middle-lg {
    align-items: center;
  }

  .row.bottom-lg {
    align-items: flex-end;
  }

  .row.around-lg {
    justify-content: space-around;
  }

  .row.between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@media only screen and (width >= 121rem) {
  .col-xl {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
  }

  .col-xl-1 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 1rem;
  }

  .col-xl-offset-1 {
    margin-left: 8.33333%;
  }

  .col-xl-2 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 1rem;
  }

  .col-xl-offset-2 {
    margin-left: 16.6667%;
  }

  .col-xl-3 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 1rem;
  }

  .col-xl-offset-3 {
    margin-left: 25%;
  }

  .col-xl-4 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 1rem;
  }

  .col-xl-offset-4 {
    margin-left: 33.3333%;
  }

  .col-xl-5 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 1rem;
  }

  .col-xl-offset-5 {
    margin-left: 41.6667%;
  }

  .col-xl-6 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 1rem;
  }

  .col-xl-offset-6 {
    margin-left: 50%;
  }

  .col-xl-7 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 1rem;
  }

  .col-xl-offset-7 {
    margin-left: 58.3333%;
  }

  .col-xl-8 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 1rem;
  }

  .col-xl-offset-8 {
    margin-left: 66.6667%;
  }

  .col-xl-9 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 1rem;
  }

  .col-xl-offset-9 {
    margin-left: 75%;
  }

  .col-xl-10 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 1rem;
  }

  .col-xl-offset-10 {
    margin-left: 83.3333%;
  }

  .col-xl-11 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 1rem;
  }

  .col-xl-offset-11 {
    margin-left: 91.6667%;
  }

  .col-xl-12 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .col-xl-offset-12 {
    margin-left: 100%;
  }

  .row.start-xl {
    justify-content: flex-start;
  }

  .row.center-xl {
    justify-content: center;
  }

  .row.end-xl {
    justify-content: flex-end;
  }

  .row.top-xl {
    align-items: flex-start;
  }

  .row.middle-xl {
    align-items: center;
  }

  .row.bottom-xl {
    align-items: flex-end;
  }

  .row.around-xl {
    justify-content: space-around;
  }

  .row.between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}

.col-gutter-lr {
  padding: 0 1rem;
}

.col-no-gutter {
  padding: 0;
}
/*# sourceMappingURL=index.0f1abbf4.css.map */
