@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,400;0,500;0,600;0,700;0,900;1,300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap'); */

@font-face {
  font-family: "MuseoCyrl500";
  src: url("./../assets/fonts/MuseoCyrl500.woff2") format("woff2"),
       url("./../assets/fonts/MuseoCyrl500.woff") format("woff");
}

/* -- colori -- */
$bgcolor:#FFF;

$header-bgcolor: #C89FA5;
$font-header-logo:MuseoCyrl500, Arial, Helvetica;
$color-header-logo: #000;
$nav-underline: #8D8F47;
$nav-color-hover: #ffffff;
$nav-color: #000;


$font-title:MuseoCyrl500, Arial, Helvetica;
$font-body:Raleway, Arial, Helvetica;
$font-body-size:16px;

$main-bgcolor: ghostwhite;


$footer-bgcolor: #222629;
$footer-fcolor:#000;
$footer-link-fcolor-hover:#4b4d29;
$footer-link-fcolor:#2a2b16;


*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}

body{
    background-color: $bgcolor;
    font-family: $font-body;
    font-size: $font-body-size;
    scroll-behavior: smooth;
    overflow-x: hidden;
}


/* NAVBAR */
.header{
  border-bottom: 1px solid $header-bgcolor;
  background-color: $header-bgcolor;
  width: 100vw;
  position: fixed;
  z-index: 998;
  box-shadow: 1px 6px 14px -3px rgba(0,0,0,0.59);
-webkit-box-shadow: 1px 6px 14px -3px rgba(0,0,0,0.59);
-moz-box-shadow: 1px 6px 14px -3px rgba(0,0,0,0.59);
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color:#FFF;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1em;
}
.nav-menu-onindex{
  display: none;
}
.nav-item {
  position: relative;
  margin-left: 3rem;
}

.langitem {
  display: none;
  
        @media only screen and (max-width: 1100px) {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          justify-content: center;  
          margin-bottom: 2rem;
          margin-top: 3rem;
        }
     
}
.langitem a{
        color: #FFF;
}
.langitem a:hover{
        color: #FFF;
}
.langitem a.active{
        color: #FFF;
}
.lang-select {
  position: relative;
  margin-left: 2rem;
        @media only screen and (max-width: 1100px) {
  margin-left: 0rem;
        display: none;
        margin-bottom: 2rem;
        }
}

.nav-link{
  font-size: 1.0rem;
  font-weight: 400;
  color:$nav-color;
}

.nav-link:hover{
  color: $nav-color-hover;
}
.nav-link.active{
  color: $nav-color-hover;
}
.nav-logo {
  font-size: 1.9rem;
  font-weight: 500;
  color:$color-header-logo;
  font-family: $font-header-logo;
    text-transform: uppercase;
}
@media only screen and (max-width: 785px) {
  .nav-logo {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 1100px) {
  .nav-logo {
    font-size: 1.3rem;
  }
  

  .nav-menu {
      position: fixed;
      left: -100%;
      top: 3.5rem;
      flex-direction: column;
      background-color: $header-bgcolor;
      width: 100%;
      text-align: center;
      transition: 0.3s;
      box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
      
  margin-right: 0;
  }

.nav-menu-onindex{
  display: block;
      top: 0.0rem;
}
  .nav-menu.active {
      left: 0;
  }

  .nav-item {
      margin: 1.5rem 0;
  }

  .nav-link{
    
    color: $nav-color-hover;
  }
  .hamburger {
      display: block;
      cursor: pointer;
      color: $nav-color-hover;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
}

@media only screen and (max-width: 320px) {
  .nav-logo {
    font-size: 1.3rem;
  }
  }
.hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}
}
/* FINE */
/* Aggiunta sottolineatura */

.nav-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 100%;
  margin-top: 0.2em;
  left: 0;
  background: $nav-underline;
  transition: transform 0.5s;
  transform: scaleX(0);
  transform-origin: right;
}
.nav-item:hover {
  color: $nav-color-hover;
}
.nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.nav-item.active {
  font-weight: 500;
  color: $nav-color-hover;
}

.nav-item.active::after {
position: absolute;
content: "";
width: 100%;
height: 3px;
top: 100%;
margin-top: 0.2em;
left: 0;
background: $nav-underline;
transform: scaleX(1);
transform-origin: left;
}


.main{
    width: 100%;
    min-height: 150px;
    padding: 2em;
    padding-left: 1em;
    padding-right: 1em;
    background-color: $main-bgcolor;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    letter-spacing: 0.05em;
    font-size: 1.1em;
line-height: 1.5em;
}

.main .container{
  width: 100%;
  min-height: 150px;
  max-width: 1000px;
}
.main .container p{
  text-align: justify;
}
.main .container h2{
  margin-bottom: 0.4em;
    font-size: 1.8em;
  @media only screen and (max-width: 768px) {
    font-size: 1.4em;
  }
}

.imgmaintext{
  display:inline;
  margin-top:  .3em;
  margin-bottom:  1em;
  border-radius:5px;
  border-style:solid;
  border-width:1px;
  border-color:rgb(155, 155, 155);
  width: 40%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.imgmainr{
  margin-left:  1.5em;
  float: right;
}
.imgmainl{
  margin-right:  1.5em;
  float: left;
}

.footer{
    width: 100%;
    padding: 1em;
    min-height: 100px;
    background-color: $footer-bgcolor;
    color: $footer-fcolor;

    
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items:center;
  justify-content:center;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#444c48+0,c89fa5+0,ffcccc+100 */
background: #444c48; /* Old browsers */
background: -moz-linear-gradient(top,  #444c48 0%, #c89fa5 0%, #ffcccc 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #444c48 0%,#c89fa5 0%,#ffcccc 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #444c48 0%,#c89fa5 0%,#ffcccc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#444c48', endColorstr='#ffcccc',GradientType=0 ); /* IE6-9 */


}


.footer a{
  color: $footer-link-fcolor;
}
.footer a:hover{
  color: $footer-link-fcolor-hover;
}
.footer .container{
 
  width: 100%;
  min-height: 100px;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items:stretch;
  justify-content:center;

}
.footer div div:nth-child(1) b{
  font-size:16px;
  line-height: 30px;
}
.footer div div:nth-child(1) ul li{
  line-height: 25px;
}


.footer div div:nth-child(1){
    font-size:0.9em;
  width: 34%;
  line-height: 1.8em;
}
  .footer div div:nth-child(2){
    font-size:0.9em;
text-align: center;
    width: 33%;
  line-height: 1.5em;
  }
.footer div div:nth-child(3){
    font-size:0.9em;
text-align: right;
width: 33%;
  line-height: 1.5em;
}

.social-icon{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: row;
    width: 100%;
  margin-bottom: 1rem;
    & a{
text-align: center;
    display: flex;
    justify-content:center;
    align-items: center;
      & img{
        width: 32px;
        height: auto;
        margin-right: .5rem;
        margin-left: .5rem;
        &:hover{
          color: #FFF;
        }
      }
    }
}
@media only screen and (max-width: 768px) {
  .footer div div:nth-child(1){
    width: 100%;
  }
  .footer div div:nth-child(2){
    margin-top: 1em;
  text-align: left;
    width: 100%;
  }
  .footer div div:nth-child(3){
    margin-top: 1em;
  text-align: left;
  width: 100%;
  }
  .footer .container{
 
    width: 100%;
    min-height: 150px;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items:stretch;
    justify-content:center;
  }


  .social-icon{
    justify-content:start;
    & a{
      & img{
        margin-right: 1rem;
        margin-left: 0;
      }
    }
}
}

.gmap{
  padding-top: 75px;
  
}


.dovesiamo{
  padding-bottom: 5em;
  & h3{
    padding-bottom: .8em;
  }
  & h1{
    padding-bottom: .5em;
  @media only screen and (max-width: 768px) {
    font-size: 1.4em;
  }
  }
  & input{
        border: 1px solid #60892fc5;
        padding: .6em;
        border-radius: 5px;
        font-size: 0.9rem;
        color: #474B4F;
        width: 100%;
        margin-bottom: 1em;
    }
& button{
        border: 2px solid #60892fc5;
        padding: .6em;
        border-radius: 5px;
        font-size: 0.9rem;
        color: #FFF;
        background-color: #474B4F;
        width: 100%;
    }
  & .datiristorante{
    line-height: 28px;
  }

  & #map_canvas {
    width: 100%;
    height: 30em;
    border-radius: 5px;
    border: 1px solid #CCC;
  }
}


.ristomenu{
  & h1{
    padding-bottom: .5em;
  @media only screen and (max-width: 768px) {
    font-size: 1.4em;
  }
  }
}



.home-header{
  width: 100%;
  max-height: 17rem;
  background-color: #FFF;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  &-logo{
      margin-top: .6em;
    font-size: 3.0rem;
    font-weight: 500;
    color:$color-header-logo;
    font-family: $font-header-logo;
    text-transform: uppercase;
    line-height: 50px;
    margin-left: 0.2em;
    @media only screen and (max-width: 785px) {
     
      margin-top: 1em;
        font-size: 2rem;
    line-height: 35px;
    font-weight: 500;
      
    }
    @media only screen and (max-width: 400px) {
  
      margin-top: 1.8rem;
      font-size: 1.4rem;
    line-height: 25px;
    font-weight: 500;
    
    }
    
  &-link{
        font-size: 1rem;
    font-weight: normal;
    line-height:2rem;
        overflow: hidden;
      
      & a{
        color: #8D8F47;
        font-size: 1.5rem;
        overflow: hidden;
        font-weight: 600;
        
        &:first-of-type{
          margin-right: 1rem;
        }
        &:last-of-type{
          margin-left: 1rem;
        }
        @media only screen and (max-width: 785px) {
        
        font-size: 1.2rem;
    line-height:1.7rem;
          
        &:first-of-type{
          margin-right: .2rem;
        }
        &:last-of-type{
          margin-left: .2rem;
        }
        }
        @media only screen and (max-width: 400px) {
      
        font-size: 1rem;
    line-height:1.5rem;
        &:first-of-type{
          margin-right: .2rem;
        }
        &:last-of-type{
          margin-left: .2rem;
        }
        }
        &:hover{
            text-decoration: underline;
        }
      }
  }
  }
  & img{
    max-height: 9em;
     @media only screen and (max-width: 785px) {
     
      max-height: 6em;
      
    }
    @media only screen and (max-width: 400px) {  
    max-height: 4em;
    
    }
  }
}




.undernav {
    width: 100%;
    padding: 20px 0 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 450px;
    @media only screen and (max-width: 768px) {
        padding:0px;
        width: 100%;
        justify-content:stretch;
        min-height: 300px; 
    }
}


.undernav_home{
    width: calc(100vw - 0em);
    background-color: #FFF;
    margin: auto;
    background-image: url(./../assets/img/_29A9446.jpg?as=webp&width=1080);
    min-height: 80vh;
  margin-bottom: 0em;
}


.undernav_menu_page{

    /*?as=webp&width=1920*/
    background-image: url(./../assets/img/cucinajanakim.jpg);
}
.undernav_dove_page{
 /*./../assets/img/Schermata2022-04-13alle11.11.42.png*/
    background-image: url(./../assets/img/BRACE-2018-120dpi-50copia.jpg);
}
.undernav_wine{

    background-image: url(./../assets/img/BRACE-2018-120dpi-19.jpg);
}

.undernav_cancel{

    background-image: url(./../assets/img/osteria-dovesiamo.jpg);
}
.undernav_osteria{

    background-image: url(./../assets/img/BRACE-2018-120dpi-22.7dd8a524.jpeg);
}
.undernav_prodotti{

    background-image: url(./../assets/img/prodotti_osteria.jpg);
}
.undernav_produttori{

    background-image: url(./../assets/img/produttori.jpg);
}



